.layout-container {
   width: 100%;
   height: 100%;
   left: 2%;
   position:relative;
   margin:2%;
   align-items: "center";
   align-content: "center";
}

.test-stack{
    position:relative;
    width: 40%;

}

.test-stack01{
position: absolute;
left:5%;   

}

    .test-stack02:hover{
        fill: black;  
        }

  
.sunPVClass {
    color: black;
    fill: black;
  }
  
  .sunPVClass:hover {
    box-shadow:

    inset 0 0 50px #fff,      /* inner white */
    inset 10px 0 80px rgb(247, 245, 247),   /* inner left magenta short */
    inset -10px 0 80px rgb(248, 250, 250),  /* inner right cyan short */
    inset 10px 0 300px rgb(204, 198, 204),  /* inner left magenta broad */
    inset -10px 0 300px #0ff, /* inner right cyan broad */
    0 0 20px #fff,            /* outer white */
    -10px 0 20px rgb(59, 150, 6),        /* outer left magenta */
    10px 0 20px rgb(10, 206, 59);         /* outer right cyan */
  }


.test-stack02{
    font-size: 80%;
    position: relative;
    width: 15%;
    height: 20%;
    left:22%;  
    padding:1%;
       box-shadow:
       inset 0 0 50px #fff,      /* inner white */
       inset 10px 0 80px rgb(247, 245, 247),   /* inner left magenta short */
       inset -10px 0 80px rgb(248, 250, 250),  /* inner right cyan short */
       inset 10px 0 300px rgb(204, 198, 204),  /* inner left magenta broad */
       inset -10px 0 300px #0ff, /* inner right cyan broad */
       0 0 20px #fff,            /* outer white */
       -10px 0 20px rgb(59, 150, 6),        /* outer left magenta */
       10px 0 20px rgb(10, 206, 59)
       ;  
    
       animation: 1s ease-in-out infinite alternate textScaleAndShow;

    }


.navigation {
    width:fit-content;
    padding:1%;

}




.header {
    flex-direction: row;
    justify-content: flex-end;
    color: white;
    background-color: #064987;
    width: 100%;
}

.header-user {
    color: white;
    
    padding-left: 1%;
    align-content:end;
    padding-bottom: 0.5%;
}
.logo {
     height: 100%;
     width: 60%;
    left: 1%;
    padding: 0.2%;
    margin: 0.2%;
    background-color: white;
}

.row {
    padding:0.5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
    
}


@keyframes handMovement {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-0.25%);
      transform: rotate(1.5deg);
    }
  }

  @keyframes textScaleAndShow {
    from {

      /* opacity: 0.8; */
      /* transform: scale(0.5); */
    }
    to {
      opacity: 1;
      transform: scale(0.95);
    }
  }

  .g002 {
    fill: green ;
  }

  .g002:hover {
    fill: black;
    transform-origin: "translate(21.719 -11.704) matrix(.15052 0 0 .27474 115.247 68.816) matrix(2.07437 0 0 .976 -880.636 -203.31)";
    animation: 1s ease-out infinite alternate textScaleAndShow;
  }


  
  .g003 {
    fill: "none" ;
    height: 12%;
  }

  .g003:hover {
    height: 80px;
    fill: black !important;
        transform-origin:initial;
  }

  @keyframes textScaleAndShow02 {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
      transform: scale(1, 2);
    }
  }