.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: black !important;
}

.MuiInputLabel-root {
  color: rgb(183 174 174) !important;
}

input[type="text"],
input[type="password"] {
  border: none;
  color: black !important;
  width: 100% !important;
  border-radius: 7px !important;
}

/* .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: green !important;
} */
