

.AppBar{
    background-color:white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
}



.formDiv {
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    height: 80%;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.formDiv001 {
    align-items: left;
    justify-content: center;
    font-family: sans-serif;
    padding: 1%;
    margin: 1%;
    flex-direction: column;
    height: 80%;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.form {
    border-radius: 15px;
    background-color: rgb(223, 224, 223);
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    gap: 10px;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    box-shadow: 0 10px 8px 0 rgba(240, 239, 239, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.app {
    
    height:100%;
    background-color: #064987;
  
}

input[type="text"],
input[type="password"] {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: #01d28e;
    border: 1px solid #01d28e;
    color: #fff;
    padding: 10px 20px;
}

    input[type="submit"]:hover {
        background: #6cf0c2;
    }

.button-container {
    display: flex;
  justify-content:right;
    padding: 8px;
  
}

.submit-button {
    display: flex;
    justify-content: center;
    padding: 8px;
    background: #064987;
    color: white;
    right: 2%;
    font-size: 18px;
    border:1px solid white;
}


.list-container {
    display: flex;
}

.error {
    color: red;
    font-size: 12px;
}

.title {
    color: #064987;
    font-size: 25px;
    margin-bottom: 20px;
}

.input-container {
    color: #064987;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
}

.logo001 {
    height:50%;
    width: 100%;
    background-color: white;
}

.logo {
    height:50%;
    width: 100%;
    background-color: white;
}