.headerLogo {
    height: 2%;
    left: 1%;
    padding: 0.2%;
    margin: 0.2%;
    background-color: white;
}



.menuList {
    background-color: rgb(228, 222, 222);
    position: absolute;
    width: 10%;
    top: 50%;
}

.outlet {
    height:fit-content;;
    position: relative;
}
.mainSection {
    position: fixed;
    align-content: center;
    flex-direction: row;
    display: flex;
}

.mainDiv {
    display: flex;
    flex-direction: row;
    position: relative;
    width: "100vh";
    height: 100%;
    background-color: rgba(247, 245, 245, 0.651);   
}


mainDiv,
.content{
    height: "100vh";
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    background-color: rgba(247, 245, 245, 0.651);
}


.topbar{
    height: "5%";
    position: sticky;
}
