.boxx {
  width: 100%;
  max-width: 300px;
}
.chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 15000px;
  overflow-x: scroll;
}

.numbers {
  color: black;
}

.units {
  color: #bc3131 !important;
  background-color: red;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .boxx {
  }
}
