.hoveringEffect01 {
  cursor: pointer;

  box-shadow: inset 0 0 50px #fff, inset 10px 0 80px rgb(247, 245, 247),
    inset -10px 0 80px rgb(248, 250, 250), inset 10px 0 300px rgb(204, 198, 204),
    inset -10px 0 300px #0ff, 0 0 20px #fff, -10px 0 20px rgb(59, 150, 6),
    10px 0 20px rgb(10, 206, 59);
}

.hoveringEffect01:hover {
  cursor: pointer;
  filter: blur(5px);
  filter: brightness(0.4);
  filter: contrast(200%);
  filter: drop-shadow(16px 16px 20px blue);
  filter: hue-rotate(90deg);
  filter: invert(75%);
  filter: opacity(25%);
  filter: saturate(30%);
  filter: sepia(60%);
}

.details {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 70%;
  height: auto;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.3%;
}

.details2 {
  position: absolute;
  top: 10%;
  width: 60%;
  height: auto;
  background-color: #e9e6e6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1%;
}
